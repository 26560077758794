<template>
  <div class="myActivityList">
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      ref="list"
      :finishedText="finishedText"
    >
      <div
        class="activityItem"
        v-for="(item, index) in listData"
        :key="index"
        @click="toDetail(item)"
      >
        <div class="item-l"><img :src="item.activityPicture" alt="" /></div>
        <div class="item-r">
          <h3>
            {{ item.activityName }}
          </h3>
          <p v-if="item.scheduleList[0].dayTimeBucket != ''">
            场次：{{ weekList[item.scheduleList[0].week]
            }}{{ item.scheduleList[0].dayTimeBucket }}
          </p>
          <p class="p-apply">
            报名开始时间：{{
              item.scheduleList && item.scheduleList[0]
                ? item.scheduleList[0].applyStartDate
                : item.applyStartDate
            }}
          </p>
          <p class="p-activity">
            活动开始时间：{{
              item.scheduleList && item.scheduleList[0]
                ? item.scheduleList[0].startDate
                : item.startDate
            }}
          </p>
        </div>
        <div class="status"></div>
      </div>
    </v-list>
  </div>
</template>

<script>
import { myActivityUrl } from "./api.js";
import { getOption } from "@/utils/utils.js";
import { activityStatus } from "./map.js";
export default {
  name: "myActivityList",
  data() {
    return {
      weekList: [
        "",
        "每周一",
        "每周二",
        "每周三",
        "每周四",
        "每周五",
        "每周六",
        "每周日",
      ],
      finishedText: "没有更多了",
      activityStatus,
      finished: false,
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
      listData: [],
    };
  },
  mounted() {
    // this.getActivityList();
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
  },
  methods: {
    toDetail(item) {
      this.$router.push({
        name: "orderDetail",
        query: {
          activityId: item.activityId,
          isApply: 1,
          applyId: item.applyId,
          scheduleId: item.scheduleId,
        },
      });
    },
    getOption(status) {
      if (getOption(status, activityStatus, "key")["value"]) {
        return getOption(status, activityStatus, "key")["value"];
      } else {
        return "";
      }
    },
    getActivityList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        userId: this.userId,
      };
      this.$axios.get(`${myActivityUrl}`, { params: params }).then((res) => {
        console.log(res, 333);
        if (res.code === 200) {
          if (res.data.pages <= res.data.current) {
            this.finished = true;
          } else {
            this.$refs.list._data.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          res.data.records.forEach((item) => {
            if (item.activityPicture) {
              item.activityPicture = this.$handleImg(
                400,
                400,
                item.activityPicture
              );
            }
            this.listData.push(item);
          });
        } else {
        }
      });
    },
    onLoad() {
      this.getActivityList();
    },
  },
};
</script>
<style lang="less" scoped>
.myActivityList {
  padding: 32px;
  background: #f5f5f5;
  min-height: 100vh;
  box-sizing: border-box;
  .activityItem {
    display: flex;
    height: 224px;
    background: #ffffff;
    border-radius: 16px;
    margin-bottom: 32px;
    .item-l {
      width: 224px;
      height: 100%;
      margin-right: 16px;
      img {
        width: 100%;
        border-radius: 16px 0 0 16px;
        height: 100%;
      }
    }
    .item-r {
      flex: 1;
      padding: 24px 32px 0 0;
      h3 {
        width: 400px;
        font-size: 32px;
        font-weight: bold;
        height: 64px;
        color: #333333;
        line-height: 44px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      p {
        font-size: 24px;
        padding-left: 24px;
        font-weight: 400;
        color: #646668;
        line-height: 36px;
      }
      .p-apply {
        margin: 12px 0 4px;
      }
      .p-apply,
      .p-activity {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          width: 12px;
          height: 12px;
          background: #1ebb70;
          border-radius: 50%;
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
      .p-activity {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 12px;
          height: 12px;
          background: #3a69ed;
          border-radius: 50%;
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }
  }
}
</style>
